import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

function App() {
  return (
    <div className="container">
      <Header />
      <Menu />
      <Footer />
    </div>
  );
}

function Header() {
  return (
    <header className="header">
      <div className="site-branding">
        <a className="brand" href="http://linailvesdesign.se" rel="home">
          <img
            src="cropped-Linailvesdesignfarglogga.png"
            alt="LinaIlvesDesign logo"
          />
        </a>
      </div>
    </header>
  );
}

function Menu() {
  return (
    <main className="menu">
      <h2>
        <strong>Måleri, illustration och design</strong>
      </h2>

      <h1>
        Jag heter Lina Ilves och jag sysslar med fine art, illustration och
        grafisk design i olika former. Jag målar i gouche/akvarell men också
        digitalt. Jag säljer prints på beställning och Jag hjälper gärna till
        med att designa logotyper, layouter, affischer, bokomslag m.m.
      </h1>
      <div className="block-spacer"></div>
      <div className="block-buttons">
        <div className="block-button"></div>
        <div className="block-button">
          <a className="element-button" href="mailto:linaella.ilves@gmail.com">
            Kontakta mig via mail
          </a>
        </div>
      </div>
    </main>
  );
}

function Footer() {
  return (
    <footer className="footer">
      <div className="site-container">
        <div className="site-footer-row">
          <div className="site-footer-section">
            <div className="footer-widget-area">
              <div className="footer-widget-area-inner footer-social-inner">
                <div className="footer-social-wrap">
                  <div className="footer-social-inner-wrap">
                    <a
                      aria-label="Facebook"
                      rel="noopener"
                      href="https://facebook.com/Lina-Ilves-Design-102919819065083"
                      className="social-button"
                    >
                      <span className="svg-iconset">
                        <svg
                          className="svg"
                          fill="currentColor"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <title>Facebook</title>
                          <path d="M31.997 15.999c0-8.836-7.163-15.999-15.999-15.999s-15.999 7.163-15.999 15.999c0 7.985 5.851 14.604 13.499 15.804v-11.18h-4.062v-4.625h4.062v-3.525c0-4.010 2.389-6.225 6.043-6.225 1.75 0 3.581 0.313 3.581 0.313v3.937h-2.017c-1.987 0-2.607 1.233-2.607 2.498v3.001h4.437l-0.709 4.625h-3.728v11.18c7.649-1.2 13.499-7.819 13.499-15.804z"></path>
                        </svg>
                      </span>
                    </a>
                    <a
                      href="https://www.instagram.com/linailvesdesign"
                      aria-label="Instagram"
                      rel="norefferer"
                      className="social-button"
                    >
                      <span className="svg-iconset">
                        <svg
                          className="svg"
                          fill="currentColor"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <title>Instagram</title>
                          <path d="M7 1c-1.657 0-3.158 0.673-4.243 1.757s-1.757 2.586-1.757 4.243v10c0 1.657 0.673 3.158 1.757 4.243s2.586 1.757 4.243 1.757h10c1.657 0 3.158-0.673 4.243-1.757s1.757-2.586 1.757-4.243v-10c0-1.657-0.673-3.158-1.757-4.243s-2.586-1.757-4.243-1.757zM7 3h10c1.105 0 2.103 0.447 2.828 1.172s1.172 1.723 1.172 2.828v10c0 1.105-0.447 2.103-1.172 2.828s-1.723 1.172-2.828 1.172h-10c-1.105 0-2.103-0.447-2.828-1.172s-1.172-1.723-1.172-2.828v-10c0-1.105 0.447-2.103 1.172-2.828s1.723-1.172 2.828-1.172zM16.989 11.223c-0.15-0.972-0.571-1.857-1.194-2.567-0.754-0.861-1.804-1.465-3.009-1.644-0.464-0.074-0.97-0.077-1.477-0.002-1.366 0.202-2.521 0.941-3.282 1.967s-1.133 2.347-0.93 3.712 0.941 2.521 1.967 3.282 2.347 1.133 3.712 0.93 2.521-0.941 3.282-1.967 1.133-2.347 0.93-3.712zM15.011 11.517c0.122 0.82-0.1 1.609-0.558 2.227s-1.15 1.059-1.969 1.18-1.609-0.1-2.227-0.558-1.059-1.15-1.18-1.969 0.1-1.609 0.558-2.227 1.15-1.059 1.969-1.18c0.313-0.046 0.615-0.042 0.87-0.002 0.74 0.11 1.366 0.47 1.818 0.986 0.375 0.428 0.63 0.963 0.72 1.543zM17.5 7.5c0.552 0 1-0.448 1-1s-0.448-1-1-1-1 0.448-1 1 0.448 1 1 1z"></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer-section">
            <div className="footer-widget-area">
              <div className="footer-widget-area-inner footer-social-inner">
                <div className="footer-html">
                  <div className="footer-html-inner">
                    <p>© 2024 Lina Ilves Design</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

// React v18
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
